@import '../../../app/index';

.info_modal_wrapper {
  position: relative;
  display: inline-block;
  z-index: 10000;
  box-shadow: var(--shadow-1);

  &.hover_behavior {
    &:hover {
      .modal_content {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .search_icon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    font-weight: var(--fnt-weight-medium);
    background: var(--clr-grey-light);
    color: var(--clr-secondary);
  }

  .modal_content {
    padding: 24px;
    width: 400px;
    margin: 0 8px;
    transition: .2s;
    background: var(--clr-secondary);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--brd-radius-2);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 20px;
    left: 20px;
    box-shadow: var(--shadow-1);

    p {

    }

    &.active {
      opacity: 1;
      visibility: visible;
    }

    .close_button {
      width: 32px;
      box-shadow: var(--shadow-2);
      background: var(--clr-secondary);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      color: var(--clr-primary);
      font-weight: var(--fnt-weight-medium);
      position: absolute;
      user-select: none;
      border-radius: 50%;
      right: 0;
      top: 0;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 28px;
      padding-bottom: 2px;
      transform: translate(50%, -50%);
    }
  }
}