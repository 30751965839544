@import "src/app/index";

.selected_wrapper {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 2px solid var(--clr-grey-1);

  svg {
    color: var(--clr-primary)
  }
}