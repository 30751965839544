@import "src/app/index";

.price {
  background: var(--clr-primary);
  padding: var(--space-2) var(--space-3) var(--space-2) var(--space-3);
  box-sizing: border-box;
  //@extend %loading;
  //&[data-loading=true] {
  //  width: 120px;
  //}
  margin: var(--space-4) 0;
  text-align: center;
  border-radius: var(--brd-radius-1);
  width: 100%;
}

.card {
  position: relative;
  background: var(--clr-secondary);
  border-radius: var(--brd-radius-2);
  display: flex;
  width: 250px;
  flex-direction: column;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: 24px;

  .selector {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    transform: translateY(100%);
    background: red;
  }

  .delete {
    position: absolute;
    top: 0;
    cursor: pointer;
    transition: .15s;
    right: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--clr-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    box-shadow: var(--shadow-3);

    svg {
      color: var(--clr-primary);
    }
  }
  &:hover {
    .delete {
      opacity: 1;
    }
  }
}

