@import "src/app/index";

.notifications {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  overflow: scroll;
  flex-grow: 0;
  height: 100%;
  box-sizing: border-box;
}

.mark {
  width: 25px;
  height: 25px;
  background: var(--clr-primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  font-size: var(--fnt-size-2);
}

.b {
}