@import "src/app/index";

.wrapper {
  margin: 0.25rem;
  font-size: 16px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background: transparent;

  .label {
    user-select: none;
    display: inline-block;
    padding: 4px 16px 4px 8px;
    border-radius: var(--brd-radius-1);
    color: var(--clr-grey-1);
  }

  .direction_pointer {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 2;
    background: var(--clr-background);
    right: 12px;
    top: 50%;
    transform: translate(100%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;

    .lines_wrapper {
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 4px;
      cursor: pointer;
      height: auto;
      width: 70%;
      z-index: -1;

      .first_line, .second_line, .third_line {
        border-radius: var(--brd-radius-3);
        height: 2px;
        background: var(--clr-fnt-primary);
        transition: .1s;
      }

      .first_line {
        width: 33%;
      }

      .second_line {
        width: 66%;
      }

      .third_line {
        width: 99%;
      }

      &[data-descending=true] {
        .first_line {
          width: 99%;
        }

        .third_line {
          width: 33%;
        }
      }
    }
  }

  &[data-active=true] {
    .label {
      background: var(--clr-primary);
      font-weight: var(--fnt-weight-medium);
      color: var(--clr-fnt-black);
    }

    .direction_pointer {
      background: var(--clr-background);
    }
  }
}


