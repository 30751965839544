@import "variables";
@import "templates";

body, input {
  padding: 0;
  margin: 0;
  background: var(--clr-background);
  font-family: "Inter", serif;
}

input::-webkit-input-placeholder {
  color: var(--clr-grey-1);
  font-family: "Inter", serif;
  font-weight: var(--fnt-weight-regular);
}

button {
  outline: none;
  border: none;
}