@import 'src/app/index';

.input_wrapper {
  position: relative;
  input {
    padding-right: 24px;
  }
}

.hide_button {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 4px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
}