@import 'src/app/index';

.wrap {
  position: relative;
  //overflow: hidden;
  width: 100%;
  height: 500px !important;
  background-size: 100%;
  border-radius: var(--brd-radius-2);
  background-size: 100%;
}

.slider_wrapper, .background_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider_wrapper {
  z-index: 12;
  display: flex;
  justify-content: center;
}

.background_image {
  overflow: hidden;
  border-radius: var(--brd-radius-2);
  z-index: 2;
  @extend %loading;

  img {
    width: 110%;
    height: 110%;
    filter: blur(8px);
  }

  &.no_image {
    background: var(--clr-primary-light);
  }
}

.counter_wrapper, .extra_button {
  position: absolute;
  z-index: 13;
  bottom: var(--space-4);
  right: var(--space-4);
}

.extra_button {
  left: var(--space-4);;
}
