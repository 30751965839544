@import 'src/app/index';

.error_boundary {
  background: var(--clr-secondary);
  border-radius: var(--brd-radius-3);
  width: auto;
  margin: 16px;
  box-shadow: var(--shadow-1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 500px;
  padding: 32px;
  box-sizing: border-box;

  .code_wrapper {
    font-size: 96px;
    color: var(--clr-primary);
  }

  .message_wrapper {

  }
}