@import "src/app/index";

.navigation_buttons {
  display: flex;
  box-shadow: -26px 1px 30px 0px rgba(34, 60, 80, 0.2);
  gap: var(--space-4);
  padding: var(--space-4) var(--space-4) var(--space-4) 0;
  width: 100%;
  justify-content: flex-end;
  border-bottom-right-radius: var(--brd-radius-2);
}