@import "src/app/index";

.mark {
  font-size: var(--fnt-size-5);
  font-weight: var(--fnt-weight-medium);
  margin: 0;

  h3 {
    margin: 0;
  }

  &.type {
    &_good {
      color: var(--clr-success);
    }

    &_medium {
      color: var(--clr-warning);
    }

    &_bad {
      color: var(--clr-error);
    }
  }
}

.mark_variant_image_viewer {

}

.card {
  width: 100%;
  height: auto;
  border-radius: var(--brd-radius-2);
  box-sizing: border-box;
  padding: var(--space-4);
  background: var(--clr-secondary);
}