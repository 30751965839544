.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  margin-left: 16px;
  padding-right: 36px;

  &:focus {
    outline: none;
  }

  .values {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .value_wrapper {
    position: relative;
    box-sizing: border-box;

      input {
        background: transparent;
        border: none;
        padding: 4px;
        cursor: pointer;
        outline: none;
        border-radius: var(--brd-radius-1);
        width: 100%;
        transition: .2s;
        color: var(--clr-fnt-primary);

        &:hover {
          background: var(--clr-primary-light);
        }

        &:focus {
          background: var(--clr-primary-light);
        }
      }

    &:last-child {
      input {
        text-align: end;
      }
    }
  }

  .range_wrapper {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin: 0 34px;

    &:focus {
      outline: none;
    }

    .selected_range {
      position: absolute;
      top: 50%;
      box-sizing: border-box;
      background: var(--clr-primary);
      height: 8px;
      border-radius: 16px;
      transform: translateY(-50%);
      z-index: 1;
      user-select: none;
      pointer-events: none;

      &[data-some-captured=false] {
        transition: .3s;
      }
    }

    .track {
      height: 26px;
      position: relative;
      box-sizing: border-box;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 100%;
        height: 8px;
        border-radius: 16px;
        transform: translateY(-50%);
        background: var(--clr-grey-1);
      };
    }

    .thumb {
      background: var(--clr-secondary);
      width: 24px;
      height: 24px;
      user-select: none;
      border-radius: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: 1;

      &::before {
        content: '';
        top: 50%;
        left: 50%;
        position: absolute;
        width: 70%;
        height: 70%;
        transition: .2s;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background: var(--clr-primary-light);
        z-index: 1;
      }

      &::after {
        content: '';
        top: 50%;
        left: 50%;
        position: absolute;
        width: 70%;
        height: 70%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background: var(--clr-primary);
        z-index: 2;
      }

      &[data-captured=false] {
        transition: .3s;
      }

      &[data-captured=true] {
        &:before {
          width: calc(100% + 4px);
          height: calc(100% + 4px);
        }
      }
    }

    .min_thumb {
      transform: translate(-100%, -50%);
    }

    .max_thumb {
      transform: translate(0%, -50%);
    }
  }
}
