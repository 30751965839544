.modal_wrapper {
  padding: var(--space-4);
  box-sizing: border-box;
  width: 100%;
  z-index: 10000000000000000;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  height: auto;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.25);
}