@import 'src/app/index';



.details {
  min-height: auto;
  width: 100%;;

  &.opened {
   .container {
     height: auto;
   }

    .card {
      border-color: var(--clr-primary);
    }
  }

  .container {
    width: 100%;
    overflow: hidden;
    min-height: auto;
    box-sizing: border-box;
    height: 0;
    padding-bottom: var(--space-4);
    padding-left: var(--space-4);
    padding-right: var(--space-3);
  }

  .card {
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    padding: var(--space-3) var(--space-4);
    border-bottom: 2px solid var(--clr-grey-1);

  }
}