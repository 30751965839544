@import "src/app/index";

.selector_wrapper {
  position: relative;
  display: inline-block;
  outline: none;
  width: 100%;
  cursor: pointer;
  font-weight: var(--fnt-weight-medium);
  font-size: var(--fnt-size-3);

  .current_value_wrapper {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: var(--clr-grey-light);
    border-radius: var(--brd-radius-1);
    min-height: 34px;
    color: var(--clr-fnt-primary);

    &.multiple {
      padding: 4px 8px;
    }
  }

  .input_container {
    position: relative;
    box-sizing: border-box;
    font-size: 14px;
    width: 100%;
    background: var(--clr-grey-light);
    border-radius: var(--brd-radius-1);
    padding: 8px;

    input::-webkit-input-placeholder {
      color: var(--clr-grey-1);
      font-weight: var(--fnt-weight-regular);
    }

    &.hide_pointer {
      input {
        cursor: pointer;
        caret-color: transparent;
      }
    }

    input {
      outline: none;
      border: none;
      color: var(--clr-fnt-primary);
      width: 100%;
      font-weight: var(--fnt-weight-medium);
      font-size: var(--fnt-size-3);
      background: transparent;
    }

    .mask {
      position: absolute;
      width: 100%;
      pointer-events: none;
      overflow: hidden;
      top: 0;
      left: 0;
      padding: 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      color: var(--clr-grey-1);
      text-wrap: none;

      .hidden_part {
        opacity: 0;
        visibility: hidden;
      }

      .visible_part {
        text-wrap: none;
        color: var(--clr-grey-1);
      }
    }
  }

  .multiple_options_wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 5px;

    .placeholder_wrapper {
      color: var(--clr-grey-1);
      font-weight: var(--fnt-weight-regular)
    }

    .multiple_option {
      display: inline-flex;
      padding: 2px 4px;
      justify-content: space-between;
      align-items: center;
      background: white;
      flex-grow: 1;
      border-radius: var(--brd-radius-1);
      background: var(--clr-secondary);

      .close {
        border-radius: var(--brd-radius-1);
        padding: 2px;
        width: 16px;
        height: 16px;
        justify-content: center;
        display: flex;
        align-items: center;
        background: var(--clr-grey-light);
        color: var(--clr-secondary);
        cursor: pointer;
      }
    }
  }

  .options_wrapper {
    position: absolute;
    left: 50%;

    z-index: 1200;
    width: 100%;
    border-radius: var(--brd-radius-1);

    background: var(--clr-secondary);
    cursor: pointer;
    padding: 8px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    box-shadow: var(--shadow-2);

    &.active {
      opacity: 1;
      visibility: visible;
    }

    &.selector__pos {
      &_bottom {
        bottom: -8px;
        transform: translate(-50%, 100%);
      }

      &_top {
        top: 8px;
        transform: translate(-50%, -100%);
      }
    }

    .options_container {
      display: inline-flex;
      flex-direction: column;
      overflow-y: scroll;
      align-items: center;
      gap: 8px;
      width: 100%;
      background: transparent;

      &::-webkit-scrollbar {
        display: none;
        appearance: none;
      }
    }

    .option {
      box-sizing: border-box;
      width: 100%;
      transition: .3s;
      padding: 4px;
      border-radius: var(--brd-radius-1);
      text-align: center;
      color: var(--clr-fnt-primary);
      border: 1px solid var(--clr-primary);

      &:hover {
        background: var(--clr-primary-light);
        border-color: transparent;
      }

      &:focus {
        background: var(--clr-primary-light);
        outline: none;
        border-color: transparent;
      }

      &:focus {
        background: var(--clr-primary-light);
        outline: none;
        border-color: transparent;
      }

      &.active {
        color: var(--clr-secondary);
        background: var(--clr-primary);
      }
    }
  }

}