@import "src/app/index";


.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-2);

  .edited_message {
    background: var(--clr-secondary);
    padding: var(--space-3) var(--space-4);
    display: flex;
    gap: var(--space-3);
    align-items: center;
  }

  .replied_message {
    background: var(--clr-secondary);
    padding: var(--space-3) var(--space-4);
    display: flex;
    gap: var(--space-3);
    justify-content: space-between;
    align-items: center;
  }

  .decline_selection {
    width: 20px;
    height: 20px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid var(--clr-primary);

    svg {
      color: var(--clr-primary);
    }
  }

  .selected_messages_menu {
    display: flex;
    justify-content: space-between;
    background: var(--clr-secondary);
    padding: var(--space-3) var(--space-4);

    .selected_els_management_buttons {
      display: flex;
      gap: var(--space-3);

      svg {
        transition: .2s;
        color: var(--clr-grey-1);

        &:hover {
          color: var(--clr-primary)
        }
      }
    }
  }
}

