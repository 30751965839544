.chat_notification_wrapper {
  width: 100%;
  max-width: 700px;
  border-radius: var(--brd-radius-2);
  display: flex;
  position: relative;
  gap: 16px;
  box-sizing: border-box;
  padding: 8px;
  z-index: 1000000;
  background: var(--clr-secondary);


  .avatar {
    width: 30px;
    height: 30px;
    align-self: center;
    border-radius: 50%;
    background: var(--clr-primary-light);
    overflow: hidden;

    img {
      width: auto;
      height: 100%;
    }
  }

  .content_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    p {
      margin: 0;
      font-size: var(--fnt-size-2);
    }
  }
}