@import 'src/app/index';

.container {
  min-height: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  overflow: scroll;
}

.extra {
  position: fixed;
  top: var(--space-5);
  left: var(--space-4);
  width: 23%;
}

.navigation_button {
  width: 100%;
  height: 100px;
  flex-grow: 1;
  background: var(--clr-grey-1);
}

.images {
  height: 100%;
  width: 50%;
  padding: var(--space-4);
  display: flex;
  flex-direction: column;
  gap: var(--space-3);

  img {
    width: 100%;
    display: inline-block;
    height: auto;
  }
}

.navigation {
  position: fixed;
  top: 50%;
  height: auto;
  max-height: 600px;
  right: 30px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transform: translateY(-50%);

  .list {
    overflow-y: scroll;
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: column;
    gap: 4px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */


  &_item {
    width: 100px;
    cursor: pointer;
    height: 80px !important;
    background: red;
    filter: brightness(60%);
    flex-grow: 1;
    border: 1px solid transparent;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.active {
      border: 1px solid var(--clr-primary);
      filter: none;
    }
  }
}