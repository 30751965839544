@import 'src/app/index';

.input_wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  max-width: 250px;
  box-sizing: border-box;
  border-bottom: var(--space-3);

  input {
    width: 100%;
    padding: 8px;
    font-weight: var(--fnt-weight-medium);
    font-size: var(--fnt-size-3);
    background: var(--clr-grey-light);
    color: var(--clr-fnt-primary);
    outline: none;
    border-radius: var(--brd-radius-1);
    box-sizing: border-box;
    border: 2px solid transparent;

    &::-webkit-input-placeholder {
      color: var(--clr-grey-1);
      font-weight: var(--fnt-weight-regular);
    }
  }

  &.width {
    &_full {
      max-width: 100%;
    }
  }

  .label_wrapper {
    font-weight: var(--fnt-weight-regular);
    color: var(--clr-grey-1);
    margin-bottom: var(--space-1);
    margin-left: var(--space-3);
  }

  .error_wrapper {
    width: 100%;
    margin-top: var(--space-3);
    text-align: start;
    color: var(--clr-red-light);
    font-size: var(--fnt-size-2);
    box-sizing: border-box;
  }

  &.has_error {
    font-weight: var(--fnt-weight-regular);
    color: var(--clr-red-light);
  }

  @extend %width;
  @extend %size;
}