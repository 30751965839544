@import "src/app/index";



.image_viewer {
  position: relative;
  display: inline-block;
  border-radius: var(--brd-radius-2);
  overflow: hidden;
  width: 100%;
  height: 100%;

  @extend %loading;

  .counter_wrapper {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-48%);
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 4;
    width: 90%;
    box-sizing: border-box;
    opacity: 0;
    visibility: hidden;
    transition: .2s;

    .additional_pages_wrapper {
      color: var(--clr-secondary);

      &.active {
        color: var(--clr-primary);
      }
    }
  }



  .image_wrapper {
    width: 100%;
    height: 100%;
    border-radius: var(--brd-radius-2);
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.blured {
      filter: blur(8px);
    }
  }

  .count_of_additiona_pages_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: var(--clr-secondary);
    text-align: center;
  }

  .not_image {
    width: 100%;
    border-radius: var(--brd-radius-2);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 16px;
    background: var(--clr-grey-light);
    font-size: 16px;
    color: var(--clr-grey-1);

    svg {
      width: 48px;
      height: 48px;
      fill: var(--clr-grey-1);
    }
  }

  &:hover .counter_wrapper {
    opacity: 1;
    visibility: visible;
  }
}

