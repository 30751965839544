@import 'src/app/index';

.profile_avatar {
  width: 220px;
  height: 280px;
  background: var(--clr-primary-light);
  border-radius: var(--brd-radius-2);
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  @extend %loading;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}