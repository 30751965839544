%selected {
  cursor: pointer;
  &[data-selected=true] {
    border-color: var(--clr-primary);
  }
}

.brend_item {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  .brend_logo {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background: var(--clr-primary-light);
  }

  .brend_title {
    padding: 2px 8px;
    border: 2px solid transparent;
    border-radius: 50px;
    @extend %selected;
    color: var(--clr-fnt-primary);
  }
}

.car_class_item {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  border: 2px solid var(--clr-grey-1);
  border-radius: var(--brd-radius-1);
  padding: var(--space-2);
  box-sizing: border-box;
  @extend %selected;


  .car_class_title {
    font-weight: var(--fnt-weight-semi-bold);
    color: var(--clr-fnt-primary);
  }

  .car_class_description {
    font-weight: var(--fnt-weight-regular);
    color: var(--clr-grey-1);
    text-align: center;
  }
}

.tag_item {
  border: 2px solid var(--clr-grey-1);
  border-radius: var(--brd-radius-1);
  padding: var(--space-2) var(--space-3);
  color: var(--clr-fnt-primary);

  @extend %selected;
}

.base_search {
  display: flex;
  flex-direction: column;
  gap: var(--space-4);
  .brends_panel_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .brends_panel {
      display: flex;
      gap: 24px;
    }
  }
}