@import "src/app/index";

.navigation {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
  width: 60%;
  max-width: 300px;
  height: 8px;
  opacity: 0;
  transition: opacity .2s;

  .track {
    height: 100%;
    border-radius: 10px;
    z-index: 10;
    background: var(--clr-grey-1);;
    overflow: scroll;
    display: flex;
    position: relative;
  }
}

.left, .right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity .2s;
}

.left {
  left: 0px;

}

.right {
  right: 0px;
}
.wrapper {
  position: relative;
  height: 450px;
  width: 100%;
  z-index: 0;
  transform: translateY(-20px);

  & + div {
    transform: translateY(-70px);
  }
  &:hover {
    .navigation, .left, .right {
      opacity: 1;
    }
  }
}

.slides {
  height: 100%;
  display: flex;
  overflow: scroll;
  position: relative;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
;
  }

  //&::-webkit-scrollbar-track{
  //  -webkit-appearance: none;
  //  background: var(--clr-primary-light);
  //  border-radius: 10px;
  //  height: 8px;
  //}
  //
  //&::-webkit-scrollbar-thumb {
  //  -webkit-appearance: none;
  //  background: var(--clr-primary);
  //  border-radius: 10px;
  //}


  img {
    height: 100%;
    user-select: none;
  }
}

