@import "src/app/index";

.header {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  z-index: 10;

  &[data-transparent=false] {
    box-shadow: var(--shadow-2);
    background: var(--clr-secondary);
  }

  .buttons_line_wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }

}

.geo_wrapper {

}
.header_wrapper {
  width: 100%;
  z-index: 10;
}