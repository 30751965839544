@import "src/app/index";

.prop_wrapper {
  width: 100%;
  height: 100%;
  background: var(--clr-secondary);
  box-sizing: border-box;
  padding: var(--space-2) var(--space-4);

  &.up {
    border-top-left-radius: var(--brd-radius-2);
    border-top-right-radius: var(--brd-radius-2);
  }

  &.down {
    border-bottom-left-radius: var(--brd-radius-2);
    border-bottom-right-radius: var(--brd-radius-2);
    padding-bottom: var(--space-4);
  }
}