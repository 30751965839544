@import 'src/app/index';

.dotes_button {
  font-weight: var(--fnt-weight-semi-bold);
  @extend %pos-flex-center;
  border-radius: 50%;
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 0;
  overflow: hidden;
  font-size: 28px;
  color: var(--clr-fnt-primary);

  img {
    width: 50%;
    height: 50%;
  }

  &.size {
    &_small {
      width: 24px;
      height: 24px;
    }

    &_medium {
      width: 28px;
      height: 28px;
    }

    &_large {
      width: 36px;
      height: 36px;
    }
  }

  &.color {
    &_primary {
      background: var(--clr-secondary)
    }

    &_secondary {
      background: var(--clr-primary-light);
    }
  }
}