@import "../../../../app/index";

.management_panel {
  background: var(--clr-grey-2);
  border-radius: var(--brd-radius-2);
  padding: var(--space-3);
  width: 100px;
  box-sizing: border-box;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.icon_button  {
  background: var(--clr-grey-light);
  border-radius: var(--brd-radius-1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;

  body[data-theme='light'] {
    color: var(--clr-fnt-primary);
  }

  body[data-theme='dark'] {
    color: var(--clr-primary) !important;
  }

}