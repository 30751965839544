@import "src/app/index";

.banned {
  padding: var(--space-1) var(--space-4);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--clr-red-light);
  border-radius: 20px;

  h4 {
    color: var(--clr-fnt-black) !important;
  }
}