@import "src/app/index";

.w {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  gap: 16px;
}

.row {
  display: grid;
  width: 100%;
  gap: var(--space-4);

  &.pattern {
    &_small_medium {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr;

      & > div:nth-child(1) {
        grid-column: 1;
        grid-row: 1;
      }

      & > div:nth-child(2) {
        grid-column: 2;
        grid-row: 1;
      }

      & > div:nth-child(3) {
        grid-column: 1;
        grid-row: 2;
      }

      & > div:nth-child(4) {
        grid-column: 2;
        grid-row: 2;
      }

      & > div:nth-child(5) {
        grid-column: 3 / 5;
        grid-row: 1 / 3;
      }
    }

    &_medium_small {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr;

      & > div:nth-child(1) {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
      }

      & > div:nth-child(2) {
        grid-column: 3;
        grid-row: 1;
      }

      & > div:nth-child(3) {
        grid-column: 4;
        grid-row: 1;
      }

      & > div:nth-child(4) {
        grid-column: 3;
        grid-row: 2;
      }

      & > div:nth-child(5) {
        grid-column: 4;
        grid-row: 2;
      }
    }

    &_large {

    }

    &_small  {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr;
    }

    &_small_one_line {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr
    }

    &_medium {
      grid-template-columns: repeat(2, 1fr);
    }

    &_small_medium_small {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr;

      & > div:nth-child(1) {
        grid-column: 1;
        grid-row: 1;
      }

      & > div:nth-child(2) {
        grid-column: 1;
        grid-row: 2;
      }

      & > div:nth-child(3) {
        grid-column: 2 / 4;
        grid-row: 1 / 3;
      }

      & > div:nth-child(4) {
        grid-column: 4;
        grid-row: 1;
      }

      & > div:nth-child(5) {
        grid-column: 4;
        grid-row: 2;
      }
    }
  }
}