@import 'src/app/index';

.user_avatar {
  border-radius: 50%;
  flex-grow: 1;
  background: var(--clr-primary-light);
  overflow: hidden;
  @extend %loading;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.size {
    &_small {
      height: 16px;
      width: 16px;
    }

    &_medium {
      height: 36px;
      width: 36px;
    }

    &_large {
      height: 48px;
      width: 48px;
    }
  }
}