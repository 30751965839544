@import 'src/app/index';

.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--space-4);
  background: var(--clr-secondary);
  border-radius: var(--brd-radius-2);
  box-shadow: var(--shadow-2);

  .rate_mark {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: var(--clr-primary);
    font-size: var(--fnt-size-6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}