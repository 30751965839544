@import 'src/app/index';

.wrapper {
  cursor: pointer;
  outline: none;
  border: none;
  z-index: 0;
  border-radius: var(--border-1);
  padding: 0 1rem;
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  justify-content: center;
  @extend %size;
  @extend %loading;
  font-weight: var(--fnt-weight-medium);

  &[data-loading=true] {
    color: transparent;
  }

  &:disabled {
    background: var(--clr-grey-light);
    cursor: not-allowed;
  }
}

.type {
  &_primary {
    color: var(--clr-fnt-black);
    background: var(--clr-primary);
  }

  &_secondary {
    color: var(--clr-fnt-black);
    background: var(--clr-primary-light);

    h4 {
      color: var(--clr-fnt-black) !important;
    }
  }

  &_underline {
    color: var(--clr-fnt-primary);
    background: transparent;
    text-decoration: underline;
    transition: .2s;

    &:hover {
      text-decoration: none;
    }
  }
  &_circle {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  &_delete {
    background: var(--clr-red-light);

  }
}



.width {
  &_auto {
    width: auto;
  }

  &_full {
    width: 100%;
  }
}

.color {
  &_primary {
    background: var(--clr-primary);
  }

  &_secondary {
    background: var(--clr-primary-light);
  }

  &_vk {
    background: #0277ff;
  }

  &_google {
    background: #dc4c39;
  }
}