.notifications_container {
  display: flex;
  flex-direction: column;
  direction: revert;
  gap: 16px;
  height: 100%;
  z-index: 100;
  position: absolute;
  padding: var(--space-5);
  box-sizing: border-box;
  width: auto;

  &.position {
    &_leftTop, &_leftBottom {
      left: 0;
    }

    &_rightTop, &_rightBottom {
      right: 0;
    }

    &_leftBottom, &_rightBottom {
      justify-content: flex-end;
    }

    &_rightTop, &_leftTop {
      justify-content: flex-start;
    }
  }
}