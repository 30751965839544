@import "src/app/index";

.notification_wrapper {
  width: 100%;
  max-width: 500px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  overflow: hidden;
  border: 2px solid black;
  box-sizing: border-box;
  background: var(--clr-secondary);
  border-radius: var(--brd-radius-2);
  position: relative;
  font-size: var(--fnt-size-3);
  font-weight: var(--fnt-weight-medium);

  &.type {
    &_success {
      border-color: var(--clr-success);
    }

    &_error {
      border-color: var(--clr-error);
    }

    &_warning {
      border-color: var(--clr-warning);
    }
  }
}

