@import 'src/app/index';

.container {
  position: relative;
  height: auto;
  box-sizing: border-box;
  margin: 0 var(--space-3);
  overflow: scroll;
  flex-grow: 1;

  &.empty_chat {

    .chat {
      align-items: center;
      justify-content: center;
    }
  }
}

.date {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: var(--space-4) 0;
  position: sticky;
  top: 0px;
  left: 0;
  z-index: 100;
  color: var(--clr-fnt-primary);

  .calendar_wrapper {
    position: absolute;
    z-index: 200;
    top: calc(100% + 12px);
  }

  span {
    width: 80px;
    cursor: pointer;
    text-align: center;
    padding: var(--space-2) var(--space-3);
    border-radius: 20px;
    background: var(--clr-secondary);
    font-size: var(--fnt-size-3);
    font-weight: var(--fnt-weight-regular);
  }
}

.chat {
  display: flex;
  flex-direction: column;
  padding: var(--space-3) 0;
  gap: var(--space-1);
  background: var(--clr-chat-background);
  flex-grow: 1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: scroll;
  position: relative;


}

.drag_form {
  background: var(--clr-grey-light);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;

  & > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 60%;
    gap: var(--space-3);
    transition: .2s;

    svg, p {
      transition: .2s;
      color: var(--clr-grey-1)
    }
  }

  &.can_drop {
    background: var(--clr-grey-1);

    svg, p {
      transition: .2s;
      color: var(--clr-grey-light)
    }

  }
}