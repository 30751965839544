@import 'src/app/index';

.load_image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .load_image_form {
    input[type='file'] {
      display: none;
    }


    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
    padding: var(--space-4);
    vertical-align: center;
    gap: var(--space-3);

    svg {
      color: var(--clr-grey-1);
    }

    &.with_separator {
      border-bottom: 2px solid var(--clr-grey-1);
      margin-bottom: var(--space-4)
    }
  }
}


.images_grid {
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--space-4);
}

.image_wrapper {
  width: 150px;
  height: 225px;
  position: relative;

  img {
    border-radius: var(--brd-radius-2);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .delete {
    position: absolute;
    right: 0;
    cursor: pointer;
    top: 0;
    transform: translate(50%, -50%);
    display: inline-flex;
    padding: var(--space-2);
    border-radius: 50%;
    box-shadow: var(--shadow-3);
    background: var(--clr-secondary);

    svg {
      color: var(--clr-primary)
    }
  }
}

.drag_form {
  background: var(--clr-grey-light);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  border-radius: var(--brd-radius-2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: var(--space-5) var(--space-4);

  & > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 60%;
    gap: var(--space-3);
    transition: .2s;

    svg, p {
      transition: .2s;
      color: var(--clr-grey-1)
    }
  }

  &.can_drop {
    background: var(--clr-grey-1);

    svg, p {
      transition: .2s;
      color: var(--clr-grey-light)
    }
  }
}


.drag_form {
  background: var(--clr-grey-light);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  border-radius: var(--brd-radius-2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: var(--space-5) var(--space-4);

  & > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 60%;
    gap: var(--space-3);
    transition: .2s;

    svg, p {
      transition: .2s;
      color: var(--clr-grey-1)
    }
  }

  &.can_drop {
    background: var(--clr-grey-1);

    svg, p {
      transition: .2s;
      color: var(--clr-grey-light)
    }
  }
}