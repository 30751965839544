@import 'src/app/index';

.input_el {
  width: 32px;
  height: 32px;
  text-align: center;
  vertical-align: center;
  border-radius: var(--brd-radius-1);
  background: #EFEFEF;
  border: 2px solid #939393;
  transition: .2s;
  cursor: pointer;

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
    border-color: var(--clr-primary-light);
  }

  &.success {
    border-color: var(--clr-primary);
  }

  &.has_symbol {
    caret-color: transparent;
  }
}

.inputs_container {
  display: flex;
  gap: 8px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

