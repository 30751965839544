@import "src/app/index";

.model_min_card {
  border-radius: var(--brd-radius-2);
  background: var(--clr-primary-light);
  width: 100%;
  box-sizing: border-box;
  height: 140px;
  position: relative;
  cursor: pointer;
  background-size: contain;
  color: white;
  overflow: hidden;

  img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .loading_back {
    width: 100%;
    height: 100%;
    height: 500px;
    background: red;
    @extend %loading;
  }

  .text_wrap {
    padding: var(--space-3);
    border: var(--brd-radius-2);
    display: inline-block;
    width: auto;
    position: absolute;
    z-index: 14;
    background: var(--clr-secondary);
    top: -1px;
    left: -1px;
    border-radius: var(--brd-radius-2);
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;

    h5 {
      color: var(--clr-fnt-primary);
    }
  }

  &.size {
    &_large {
      width: 100%;
      padding-bottom: 50%;

      h5 {
        font-size: var(--fnt-size-4);
      }
    }

    &_small {
      padding-bottom: 100%;
    }

    &_medium {
      padding-bottom: 100%;

      h5 {
        font-size: var(--fnt-size-3);
      }
    }
  }

  .count_of_ads {
    display: flex;
    gap: var(--space-3);
  }
}