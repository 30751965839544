@import 'src/app/index';

.price {
  background: var(--clr-primary);
  padding: var(--space-2) var(--space-3) var(--space-2) var(--space-3);
  box-sizing: border-box;
  margin: var(--space-4) 0;
  text-align: center;
  border-radius: var(--brd-radius-1);
  width: 100%;

  h3 {
    color: var(--clr-fnt-black);
  }
}